<template>
  <right-content>
    <div class="baseInfo">
      <div class="info-image">
        <img :src="item.img" alt="图片" />
      </div>
      <div class="info-content">
        <el-descriptions :column="2" border>
          <el-descriptions-item
            v-for="(info, index) in baseInfoList"
            :key="index"
          >
            <template slot="label">
              {{ info.title }}
            </template>
            {{ item[info.field] }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </right-content>
</template>


<script>
import RightContent from '../commonViews/RightContent.vue';
export default {
  components:{
    RightContent
  },
  created() {

    const info = this.$route.query.info;
    if (info) {
      this.item = JSON.parse(info);
    }
    console.log(this.item);
  },
  data() {
    return {
      item: "",
      baseInfoList: [
        {
          title: "党员质量监督岗名称",
          field: "name",
        },
        {
          title: "设立时间",
          field: "found_time",
        },
        {
          title: "监督岗总人数",
          field: "sentries_number",
        },
        {
          title: "岗长",
          field: "people_name",
        },
        {
          title: "党员人数(含农民工党员)",
          field: "party_number",
        },
        {
          title: "入党积极分子",
          field: "party_positive_number",
        },
        {
          title: "农民工人数",
          field: "peasant_number",
        },
        {
          title: "岗长联系电话",
          field: "people_number",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.baseInfo {
    margin: 100px 200px;
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .info-image {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info-content {
      margin-top: 30px;
      width: 100%;
    }
  }
::v-deep .el-descriptions-item__label {
  background-color: darkgray;
  width: 200px;
  font-weight: 100;
  color: black;
}
</style>
